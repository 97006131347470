import React from 'react'
import PropTypes from 'prop-types'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Portfolio = ({ data, title }) => {
  if (!data) return

  const portfolio = data.map((obj, i) => {
    return (
      <article className="6u 12u$(xsmall) work-item" key={i}>
        <OutboundLink className="image fit thumb" href={obj.url}>
          <img src={obj.thumb} alt={obj.caption} />
        </OutboundLink>

        <h3>
          <OutboundLink href={obj.url}>{obj.caption}</OutboundLink>
        </h3>
        <p>{obj.desciption}</p>

        {obj.languages && (
          <ul class="tags">
            {obj.languages.map((language, idx) => (
              <li key={idx}>{language}</li>
            ))}
          </ul>
        )}
      </article>
    )
  })

  return (
    <div>
      <h2>{title || 'Recent Work'}</h2>
      <div className="row work">{portfolio}</div>
    </div>
  )
}

Portfolio.displayName = 'Projects'
Portfolio.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default Portfolio
