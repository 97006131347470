import React from 'react'

const GetInTouch = () => (
  <>
    <h2>Get In Touch</h2>
    <div className="row">
      <div className="12u">
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <p className="hidden">
            <label>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
          </p>
          <p>
            <label htmlFor="name">
              Your Name: <input type="text" name="name" />
            </label>
          </p>
          <p>
            <label htmlFor="email">
              Your Email: <input type="email" name="email" />
            </label>
          </p>
          <p>
            <label htmlFor="message">
              Message: <textarea name="message"></textarea>
            </label>
          </p>
          <p>
            <input type="hidden" name="form-name" value="contact" />
            <button type="submit" className="button">
              Send
            </button>
          </p>
        </form>
      </div>
    </div>
  </>
)

export default GetInTouch
