import React from 'react'
import Portfolio from '../components/Portfolio'
import Layout from '../components/layout'
import GetInTouch from '../components/GetInTouch'
import projects from '../data/projects'
import SEO from '../components/Seo'

const HomeIndex = () => {
  const siteTitle = 'Andy Mortimer'
  const siteDescription = 'Senior Developer'

  return (
    <Layout>
      <SEO title={siteTitle} description={siteDescription} />

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>About me</h2>
          </header>
          <p>
            A web developer with over 20 years experience, who is comfortable
            working either in a team environment or self-directed projects. An
            adaptive individual who enjoys learning new skills and updating
            their current skill set. Confident working on all size projects
            weather small personal projects or larger e-commerce site. Holds a
            strong belief in doing comprehensive, cross-platform and
            cross-browser code that produces high-quality, good-looking, and
            useful web sites.
          </p>
          <ul>
            <li>
              Highly values thoughtful Web site design and branding and
              considers cohesive site design to be of great importance.
            </li>
            <li>
              Experienced at building e-commerce, blogs, content management
              system solutions, and custom web applications.
            </li>
            <li>
              Strives for good communication and teamwork skills and is always
              supportive to co-workers.
            </li>
          </ul>
        </section>

        <section id="two">
          <Portfolio data={projects} />
        </section>

        <section id="three">
          <GetInTouch />
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
