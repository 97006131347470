import hayes_thumb from '../assets/images/thumb/hayesandjarvis.jpg'
import hayes_full from '../assets/images/full/hayesandjarvis.jpg'
import citalia_thumb from '../assets/images/thumb/citalia.jpg'
import citalia_full from '../assets/images/thumb/citalia.jpg'
import bookfhr_thumb from '../assets/images/thumb/bookfhr.jpg'
import bookfhr_full from '../assets/images/full/bookfhr.jpg'
import austravel_thumb from '../assets/images/thumb/austravel.jpg'
import austravel_full from '../assets/images/full/austravel.jpg'

export default [
  {
    id: '1',
    thumb: hayes_thumb,
    full: hayes_full,
    caption: 'Hayes & Jarvis',
    desciption: 'Brochure Site running on Drupal 7',
    url: 'https://www.hayesandjarvis.co.uk',
    languages: ['Drupal 7', 'React', 'Javascript', 'Bootstrap'],
  },
  {
    id: '2',
    thumb: citalia_thumb,
    full: citalia_full,
    caption: 'Citalia.com',
    desciption: 'E-Commerce site running on Hybris',
    url: 'https://www.citalia.co.uk',
    languages: ['Hybris', 'JSP', 'Javascript', 'Bootstrap'],
  },
  {
    id: '1',
    thumb: austravel_thumb,
    full: austravel_full,
    caption: 'Austravel',
    desciption: 'Brochure Site running on Drupal 7',
    url: 'https://www.austravel.com/',
    languages: ['Drupal 7', 'React', 'Javascript', 'Bootstrap'],
  },
  {
    id: '2',
    thumb: bookfhr_thumb,
    full: bookfhr_full,
    caption: 'BookFHR.com',
    desciption: 'E-Commerce site running on PHP',
    url: 'https://www.bookfhr.com',
    languages: ['PHP', 'Javascript', 'Bootstrap', 'XML', 'XSLT', 'Classic ASP'],
  },
]
